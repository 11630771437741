import { css } from '@emotion/react';

const commons = css`
  * {
    word-break: break-all;
    word-break: break-word;
  }

  html {
    overflow-y: scroll;
  }

  body {
    font-size: 14px;
    color: #212121;
    height: 100vh;
  }

  ul,
  textarea {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: gray;
    }
  }
`;

export default commons;
