
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import createCache from '@emotion/cache';
import { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from '@emotion/react';
import { PersistGate } from 'redux-persist/integration/react';
import { useStore } from 'react-redux';
import { Store } from 'redux';
import setLanguage from 'next-translate/setLanguage';

import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import Head from 'next/head';
import theme from 'config/theme';
import { wrapper, StoreProperties } from 'config/store';
import { SnackbarUtilsConfigurator } from 'utils/toast';
import eventBus from 'utils/event-bus';
import { logout } from 'redux-saga/actions';
import { isIndonesianDomain } from 'utils/common';
import { setLocalStorage } from 'utils/store';
import GlobalStyled from 'styles/globalStyles/style';

export const cache = createCache({ key: 'css', prepend: true });

function MyApp({ Component, pageProps }) {
  const store: Store & StoreProperties = useStore();
  const { locale } = useRouter();

  useEffect(() => {
    setLocalStorage('language', locale);
    eventBus.on('logout', () => store.dispatch(logout()));

    if (isIndonesianDomain()) {
      setLanguage('id');
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <CacheProvider value={cache}>
      <GlobalStyled />
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SnackbarUtilsConfigurator />
        <PersistGate persistor={store.__persistor} loading={null}>
          <ThemeProvider theme={theme}>
            <Head>
              <title>Urban CV</title>
            </Head>
            <Component {...pageProps} />
          </ThemeProvider>
        </PersistGate>
      </SnackbarProvider>
    </CacheProvider>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

  return { pageProps };
};

const __Page_Next_Translate__ = wrapper.withRedux(MyApp);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  