import { css } from '@emotion/react';

export default css`
  .p-onboarding-page {
    min-height: calc(100vh - 110px);
    .p-onboarding-page__item {
      border: 1px solid transparent;
      &:hover {
        border-color: #ff792e;
      }
    }
  }

  .p-input-onboarding {
    .o-cv1 {
      position: sticky;
      top: 160px;
    }

    .p-input-onboarding__draft {
      border-radius: 25px;
      padding: 12px 24px;
    }

    .quill.ql-editor {
      padding: 0;
      overflow-y: hidden;
    }

    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
      border: 1px solid #fff;
      background: white;
      .ql-editor {
        padding: 20px;
      }
    }

    .ql-toolbar.ql-snow {
      border-bottom-color: #eeeeee;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
      padding: 3px 8px;
      width: 34px;
    }

    &__experience {
      .quill.ql-editor {
        padding: 0;
        overflow-y: hidden;
      }

      .ql-container.ql-snow {
        overflow-y: auto;
        height: 43vh;
        padding: 12px 0;
      }
    }
  }

  .p-my-cv {
    min-height: calc(100vh - 64px);
    .p-my-cv__add-template {
      line-height: 1 !important;
      background-color: #478df2;
      border-radius: 25px;
      color: white;
      padding: 6px 25px;
      font-size: 14px;
      text-transform: none;
      margin: 20px 0px;

      &:hover {
        background-color: #6fa5f0;
      }

      @media (min-width: 960px) {
        padding: 12px 25px;
        font-size: 16px;
        margin: 0px 25px 0px;
      }
    }
  }

  .p-settings-page {
    .p-settings-page__btn {
      border-radius: 25px;
      max-width: 256px;
      font-size: 14px;
      width: 100%;
      padding: 12px 0px;

      @media (min-width: 960px) {
        font-size: 16px;
      }
    }

    .p-settings-page__hover--blue {
      &:hover {
        background-color: #6fa5f0;
      }
    }

    .p-settings-page__hover--grey {
      &:hover {
        background-color: #eeeeee;
      }
    }

    .p-settings-page__disabled {
      color: darkgrey !important;
      border: 1px solid rgb(238 238 238) !important;
      cursor: auto;
      background: rgb(238 238 238) !important;
    }

    .p-settings-page__hover--primary {
      &:hover {
        background-color: #12548a;
        border-color: #12548a;
      }
    }
  }

  .p-checkout {
    .m-vertical-step {
      .m-vertical-step__stepper {
        .m-vertical-step__stepper__circle {
          background-color: #478df2;
          color: white;
        }
        .m-vertical-step__stepper__text {
          color: #478df2;
        }
      }

      .Mui-disabled {
        .m-vertical-step__stepper__circle {
          background-color: white;
          color: #e0e0e0;
        }
        .m-vertical-step__stepper__text {
          color: #e0e0e0;
        }
      }
    }
  }

  .p-shared-cv {
    .MuiButton-root {
      min-width: 56px;
      border-radius: inherit;
    }
  }
`;
