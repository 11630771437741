import { css } from '@emotion/react';

export default css`
  body {
    .c-primary-button {
      height: 36px;
      border-radius: 100px;
      background-color: #478df2;
      text-transform: none;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      padding-left: 48px;
      padding-right: 48px;

      @media (min-width: 960px) {
        height: 48px;
        font-size: 16px;
      }

      &:hover {
        background-color: #93bdf8;
      }

      .MuiCircularProgress-root {
        color: #fff;
      }
    }
    .m-header-dropdown {
      button {
        height: 36px !important;
      }
    }

    .c-primary-button--orange {
      background: #ff792e;

      &:hover {
        background: #ff9900;
      }
    }

    .c-primary-button--orange__outlined {
      background-color: #ffffff;
      border: 1px solid #ff9900;
      color: #ff9900;
      &:hover {
        background: #fff0db;
      }
    }

    .c-primary-button--trans {
      background: transparent;
      color: #212121;
    }

    .c-primary-button--small-width {
      padding-left: 12px;
      padding-right: 12px;

      @media (min-width: 960px) {
        padding-left: 26px;
        padding-right: 26px;
      }
    }

    .c-primary-button--no-shadow:hover {
      box-shadow: none;
    }
  }

  .c-login-panel-right {
    background: #00447c;
    border-radius: 0px 20px 20px 0px;
    position: fixed;
    left: 50%;
    top: 5vh;
    height: 90vh;
    width: 576px;

    .c-login-panel-right__img {
      right: 0 !important;
      top: -11px !important;
      border-radius: 0px 50px 0px 0px !important;
      min-height: 334px !important;
      bottom: auto !important;
    }
  }

  .c-login-panel-right--register {
    min-height: 620px;
    position: absolute;
    top: unset;
  }

  .MuiSnackbar-root {
    .MuiAlert-icon {
      align-items: center;
    }
    .MuiAlert-message {
      white-space: pre;
    }
  }

  .c-quill-border {
    .quill-container {
      border: 1px solid #eeeeee;
      border-radius: 8px;
    }
    .ql-editor {
      border-radius: 8px;
    }
  }

  .c-loading-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  .MuiButtonBase-root.c-backbtn {
    border: 1px solid #c8d0e0;
    border-radius: 25px;
    padding: 12px 24px;
    font-size: 14px;
    background-color: white;

    @media (min-width: 960px) {
      padding: 12px 70px;
      font-size: 16px;
    }
  }

  .MuiButtonBase-root.c-nextbtn {
    background-color: #478df2;
    border-radius: 25px;
    color: white;
    padding: 12px 24px;
    font-size: 14px;

    &:hover {
      background-color: #6fa5f0;
    }

    @media (min-width: 960px) {
      font-size: 16px;
    }
  }
`;
