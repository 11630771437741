import _defineProperty from "/home/frontend/urbancv-frontend/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
export var breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1200,
  xl: 1920
}; // Create a theme instance.

var theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(',')
  },
  spacing: 2,
  palette: {
    primary: {
      main: '#478df2'
    },
    secondary: {
      main: '#ff792e'
    },
    error: {
      main: red.A400
    },
    background: {
      "default": '#f5f6f8'
    },
    info: {
      main: '#478df2'
    }
  },
  breakpoints: {
    values: _objectSpread(_objectSpread({}, breakpoints), {}, {
      lg: 1200
    }),
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: function up(key) {
      return "@media (min-width:".concat(breakpoints[key], "px)");
    }
  }
});
theme.shadows[1] = '0px 4px 100px rgba(194, 194, 194, 0.1)';
theme.shadows[2] = '0px 10px 10px rgba(194, 194, 194, 0.1)';
theme.shadows[3] = '0px 4px 40px rgba(194, 194, 194, 0.2)';
export default theme;