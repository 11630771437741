import useRouter from 'next/router';
import noop from 'lodash/noop';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { revokeData } from 'utils/cookie';
import { API, ROUTES } from 'config/apis';

import {
  addEducationFailed,
  addEducationSuccess,
  addExperienceFailed,
  addExperienceSuccess,
  cancelCvTransactionFailed,
  cancelCvTransactionSuccess,
  changePasswordFailed,
  changePasswordSuccess,
  deleteMyCVTemplateFailed,
  deleteMyCVTemplateSuccess,
  getCertificate,
  getCvCategoryListFailed,
  getCvCategoryListSuccess,
  getCvTransactionListFailed,
  getCvTransactionListSuccess,
  getEducation,
  getEducationFailed,
  getEducationSuccess,
  getExperience,
  getExperienceFailed,
  getExperienceSuccess,
  getLanguage,
  getMajorFailed,
  getMajorSuccess,
  getMyCVTemplateList,
  getOccupationDetailsFailed,
  getOccupationDetailsSuccess,
  getOccupationListFailed,
  getOccupationListSuccess,
  getOnboardingRequirementFailed,
  getOnboardingRequirementSuccess,
  getPolicyFailed,
  getPolicySuccess,
  getProfileFailed,
  getProfileSuccess,
  getReference,
  getReferralDetailFailed,
  getReferralDetailSuccess,
  getSampleCvDetailFailed,
  getSampleCvDetailSuccess,
  getSampleCvFailed,
  getSampleCvSuccess,
  getSharedCvDetailsFailed,
  getSharedCvDetailsSuccess,
  getTermFailed,
  getTermSuccess,
  getVolunteer,
  logout,
  removeEducationFailed,
  removeEducationSuccess,
  removeExperienceFailed,
  removeExperienceSuccess,
  resetStore,
  setProfileFailed,
  setProfileSuccess,
  updateEducationFailed,
  updateEducationSuccess,
  updateExperienceFailed,
  updateExperienceSuccess,
  updateOrderingFailed,
  updateOrderingSuccess,
  verifyEmailFailed,
  verifyEmailSuccess,
} from './actions';
import {
  AddCertificate,
  AddClicks,
  AddEducation,
  AddExperience,
  AddLanguage,
  AddReference,
  AddVolunteer,
  BlockAccount,
  CancelCvTransaction,
  ChangePassword,
  CheckVerifyEmail,
  CreateMyCVTemplate,
  CreatePassword,
  DeleteCertificate,
  DeleteLanguage,
  DeleteMyCVTemplate,
  DeleteReference,
  DeleteVolunteer,
  EditProfile,
  FacebookLogin,
  ForgotPassword,
  GetCertificate,
  GetCompanyList,
  GetCvDetail,
  GetCvTemplateList,
  GetDegreeList,
  GetInterest,
  GetJobScopeList,
  GetJobTypeList,
  GetLanguage,
  GetLanguageList,
  GetMyCVTemplateList,
  GetOccupationDetails,
  GetPolicy,
  GetProfile,
  GetReference,
  GetSampleCvList,
  GetSchoolList,
  GetSharedCVDetails,
  GetSkillList,
  GetTerm,
  GetVolunteer,
  LinkedInLogin,
  Login,
  Register,
  RemoveEducation,
  RemoveExperience,
  ResendConfirmationEmail,
  SubmitContactUs,
  SubmitOnboarding,
  UpdateCertificate,
  UpdateEducation,
  UpdateExperience,
  UpdateLanguage,
  UpdateMyCVTemplate,
  UpdateOrdering,
  UpdateReference,
  UpdateVolunteer,
  VerifyEmail,
  actionTypes,
} from './interfaces';

/**
 * Action type generators
 */
export function createActionTypeOnStart(key: string) {
  return `${key}_BEGAN`;
}

export function createActionTypeOnFailure(key: string) {
  return `${key}_FAILED`;
}

export function createActionTypeOnFinish(key: string) {
  return `${key}_FINISHED`;
}

export function createActionTypeOnSuccess(key: string) {
  return `${key}_SUCCESS`;
}

/**
 * Common saga handler
 * Unify handling saga into only one standard form
 *
 * @param payload { handler, key: type }
 * @param callbacks { onBegin, onFailure, onFinish, onSuccess }
 */
function* unfoldSaga(
  { handler = noop, key, payload }: ReduxUnfoldSaga.UnfoldSagaHandlerType,
  { onBegin = noop, onFailure = noop, onFinish = noop, onSuccess = noop } = {},
) {
  yield put({ type: createActionTypeOnStart(key) });
  yield call(onBegin);
  const { data, ok } = yield call(handler);
  if (ok) {
    yield put({ type: createActionTypeOnSuccess(key), data: payload || data });
    yield call(onSuccess, data);
  } else {
    yield put({ type: createActionTypeOnFailure(key), data });
    yield call(onFailure, data);
  }
  yield put({ type: createActionTypeOnFinish(key) });
  yield call(onFinish);
}

function* loginSaga({ payload, callbacks, type }: Login) {
  const handler = async () => API.post(ROUTES.LOGIN, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* checkVerifyEmailSaga({ callbacks, type }: CheckVerifyEmail) {
  const handler = async () => API.get(ROUTES.CHECK_VERIFY_EMAIL);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getProfileSaga({ payload, callbacks }: GetProfile) {
  const { isGetFullProfile } = payload;
  const { data, ok } = yield call(() =>
    isGetFullProfile ? API.get(ROUTES.USER_PROFILE, { include: 'detail,skill' }) : API.get(ROUTES.USER_PROFILE, null),
  );
  if (ok) {
    if (isGetFullProfile) {
      yield all([
        put(getExperience()),
        put(getEducation()),
        put(getReference()),
        put(getCertificate()),
        put(getLanguage()),
        put(getVolunteer()),
      ]);
    }
    yield put(getProfileSuccess({ ...data, isGetFullProfile }));

    if (callbacks && callbacks.onSuccess) {
      yield call(callbacks.onSuccess, data);
    }
  } else {
    yield put(getProfileFailed(data));
  }
}

function* editProfileSaga(action: EditProfile) {
  const { data, ok } = yield call(() => API.patch(ROUTES.USER_PROFILE, action.payload));
  yield put(ok ? setProfileSuccess(data) : setProfileFailed(data));
}

function* blockAccountSaga(action: BlockAccount) {
  yield call(() => API.patch(ROUTES.USER_PROFILE, action.data));
  yield put(logout());
}

function* changePasswordSaga(action: ChangePassword) {
  const { data, ok } = yield call(() => API.post(ROUTES.CHANGE_PASSWORD, action.data));
  if (ok) {
    yield put(changePasswordSuccess());
    yield revokeData();
    useRouter.push('/login');
  } else yield put(changePasswordFailed(data));
}

function* forgotPasswordSaga({ type, callbacks, payload }: ForgotPassword) {
  const handler = async () => API.post(ROUTES.FORGOT_PASSWORD, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* createPasswordSaga({ type, callbacks, payload }: CreatePassword) {
  const handler = async () => API.post(ROUTES.CREATE_PASSWORD, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* registerSaga({ payload, callbacks, type }: Register) {
  const handler = async () => API.post(ROUTES.REGISTER, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* verifyEmailSaga(action: VerifyEmail) {
  const { data, ok } = yield call(() => API.post(ROUTES.VERIFY_EMAIL, action.payload));
  yield put(ok ? verifyEmailSuccess(data) : verifyEmailFailed(data));
}

function* resendConfirmationEmailSaga({ payload, callbacks, type }: ResendConfirmationEmail) {
  const handler = async () => API.post(ROUTES.RESEND_CONFIRMATION_EMAIL, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getOnboardingRequirementSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.ONBOARDING_REQUIREMENT));
  yield put(ok ? getOnboardingRequirementSuccess(data) : getOnboardingRequirementFailed(data));
}

function* getMajorSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.MAJOR));
  yield put(ok ? getMajorSuccess({ major: data }) : getMajorFailed(data));
}

function* getCvTemplateListSaga({ payload, type, callbacks }: GetCvTemplateList) {
  const handler = async () => API.get(ROUTES.LIST_CV_TEMPLATE, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getCvTransactionListSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.CV_TRANSACTION));
  yield put(ok ? getCvTransactionListSuccess(data) : getCvTransactionListFailed(data));
}

function* submitOnboardingSaga({ data, type, callbacks }: SubmitOnboarding) {
  const handler = async () => API.patch(ROUTES.PROFILE, data, { params: { include: 'detail' } });
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getMyCVTemplateListSaga({ type, callbacks }: GetMyCVTemplateList) {
  const handler = async () => API.get(ROUTES.MY_CV_TEMPLATE);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* createMyCVTemplateSaga(action: CreateMyCVTemplate) {
  const {
    payload: { isGetMyCVTemplateList, ...payload },
    callbacks,
    type,
  } = action;
  const handler = async () => API.post(ROUTES.MY_CV_TEMPLATE, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
  if (isGetMyCVTemplateList) yield put(getMyCVTemplateList());
}

function* updateMyCVTemplateSaga(action: UpdateMyCVTemplate) {
  const {
    payload: { isGetMyCVTemplateList, ...payload },
    callbacks,
    type,
  } = action;
  const handler = async () => API.patch(`${ROUTES.MY_CV_TEMPLATE}${action.payload.id}/`, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
  if (isGetMyCVTemplateList) yield put(getMyCVTemplateList());
}

function* deleteMyCVTemplateSaga(action: DeleteMyCVTemplate) {
  const { data, ok } = yield call(() => API.delete(`${ROUTES.MY_CV_TEMPLATE}${action.payload.id}/`));
  yield put(ok ? deleteMyCVTemplateSuccess(data) : deleteMyCVTemplateFailed(data));
  if (action.payload.isGetMyCVTemplateList) yield put(getMyCVTemplateList());
}

function* getEducationSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.EDUCATION));
  yield put(ok ? getEducationSuccess(data) : getEducationFailed(data));
}

function* addEducationSaga(action: AddEducation) {
  const { data, ok } = yield call(() => API.post(ROUTES.EDUCATION, action.data));
  yield put(ok ? addEducationSuccess(data) : addEducationFailed(data));
}

function* updateEducationSaga(action: UpdateEducation) {
  const { data, ok } = yield call(() => API.patch(`${ROUTES.EDUCATION}${action.data.id}/`, action.data));
  yield put(ok ? updateEducationSuccess(data) : updateEducationFailed(data));
}

function* removeEducationSaga(action: RemoveEducation) {
  const { data, ok } = yield call(() => API.delete(`${ROUTES.EDUCATION}${action.data.id}/`));
  yield put(ok ? removeEducationSuccess({ id: action.data.id }) : removeEducationFailed(data));
}

function* getExperienceSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.EXPERIENCE));
  yield put(ok ? getExperienceSuccess(data) : getExperienceFailed(data));
}

function* addExperienceSaga(action: AddExperience) {
  const { data, ok } = yield call(() => API.post(ROUTES.EXPERIENCE, action.data));

  if (ok) {
    yield put(addExperienceSuccess(data));
    if (action.callback) action.callback();
  } else addExperienceFailed(data);
}

function* updateExperienceSaga(action: UpdateExperience) {
  const { data, ok } = yield call(() => API.patch(`${ROUTES.EXPERIENCE}${action.data.id}/`, action.data));
  yield put(ok ? updateExperienceSuccess(data) : updateExperienceFailed(data));
}

function* removeExperienceSaga(action: RemoveExperience) {
  const { data, ok } = yield call(() => API.delete(`${ROUTES.EXPERIENCE}${action.data.id}/`));
  yield put(ok ? removeExperienceSuccess({ id: action.data.id }) : removeExperienceFailed(data));
}

function* updateOrderingSaga(action: UpdateOrdering) {
  const { data, ok } = yield call(() => API.post(ROUTES.ORDERING, action.payload));
  yield put(ok ? updateOrderingSuccess(data) : updateOrderingFailed(data));
}

function* getTermSaga(action: GetTerm) {
  const { data, ok } = yield call(() => API.get(ROUTES.TERM, { lang: action.payload.lang }));
  yield put(ok ? getTermSuccess(data) : getTermFailed(data));
}

function* getPolicySaga(action: GetPolicy) {
  const { data, ok } = yield call(() => API.get(ROUTES.POLICY, { lang: action.payload.lang }));
  yield put(ok ? getPolicySuccess(data) : getPolicyFailed(data));
}

function* cancelCvTransactionSaga(action: CancelCvTransaction) {
  const { ok } = yield call(() => API.post(ROUTES.CANCEL_CV_TRANSACTION(action.data.id)));
  yield put(ok ? cancelCvTransactionSuccess() : cancelCvTransactionFailed());
}

function* getSampleCVSaga(action: GetSampleCvList) {
  const {
    payload: { recommended, isAscending, ordering },
  } = action;
  const { data, ok } = yield call(() =>
    API.get(`${ROUTES.SAMPLE_CV}?recommended=${recommended}&ordering=${isAscending ? '' : '-'}${ordering}`),
  );
  yield put(ok ? getSampleCvSuccess(data) : getSampleCvFailed(data));
}

function* getCvCategoryListSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.CV_CATEGORY));
  yield put(ok ? getCvCategoryListSuccess(data) : getCvCategoryListFailed(data));
}

function* getCvDetailPageSaga(action: GetCvDetail) {
  const {
    payload: { name },
  } = action;
  const { data, ok } = yield call(() => API.get(`${ROUTES.SAMPLE_CV}${name}/`));
  yield put(ok ? getSampleCvDetailSuccess(data) : getSampleCvDetailFailed(data));
}

function* getOccupationListSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.OCCUPATION));
  yield put(ok ? getOccupationListSuccess(data) : getOccupationListFailed(data));
}

function* getOccupationDetailsSaga(action: GetOccupationDetails) {
  const { data, ok } = yield call(() => API.get(ROUTES.OCCUPATION_DETAILS(action.data.occupationId)));
  yield put(ok ? getOccupationDetailsSuccess(data) : getOccupationDetailsFailed(data));
}

function* resetOccupationDetailsSaga() {
  const occupation = { id: 0, about_me: '', name: '', skill: [], work_exp_desc: '' };
  yield put(getOccupationDetailsSuccess(occupation));
}

function* getSharedCvDetails(action: GetSharedCVDetails) {
  const { username, cv_id } = action.payload;
  const { data, ok } = yield call(() => API.get(ROUTES.SHARED_CV(username, cv_id)));
  yield put(ok ? getSharedCvDetailsSuccess(data) : getSharedCvDetailsFailed(data));
}

function* logoutSaga() {
  yield call(() => API.post(ROUTES.LOGOUT, {}));
  yield put(resetStore());
  useRouter.push('/login');
}

function* getJobScopeListSaga({ data, callbacks, type }: GetJobScopeList) {
  const handler = async () => API.get(ROUTES.JOB_SCOPE_LIST, { keyword: data.keyword });
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getDegreeListSaga({ callbacks, type }: GetDegreeList) {
  const handler = async () => API.get(ROUTES.DEGREE_LIST);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getSchoolListSaga({ data, callbacks, type }: GetSchoolList) {
  const handler = async () => API.get(ROUTES.SCHOOL_LIST, { search: data.keyword });
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getCompanyListSaga({ data, callbacks, type }: GetCompanyList) {
  const handler = async () => API.get(ROUTES.COMPANY_LIST, { name: data.keyword, mode: data.mode });
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getJobTypeListSaga({ data, callbacks, type }: GetJobTypeList) {
  const handler = async () => API.get(ROUTES.JOB_TYPE_LIST, { search: data.keyword });
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* addReferenceSaga({ data, type }: AddReference) {
  const handler = async () => API.post(ROUTES.REFERENCE, data);
  yield unfoldSaga({ handler, key: type });
}

function* getReferenceSaga({ type }: GetReference) {
  const handler = async () => API.get(ROUTES.REFERENCE);
  yield unfoldSaga({ handler, key: type });
}

function* updateReferenceSaga({ data, type }: UpdateReference) {
  const handler = async () => API.patch(`${ROUTES.REFERENCE}${data.id}/`, data);
  yield unfoldSaga({ handler, key: type });
}

function* deleteReferenceSaga({ data, type }: DeleteReference) {
  const handler = async () => API.delete(`${ROUTES.REFERENCE}${data.id}/`);
  yield unfoldSaga({ handler, key: type, payload: { id: data.id } });
}

function* addCertificateSaga({ data, type }: AddCertificate) {
  const handler = async () => API.post(ROUTES.CERTIFICATION, data);
  yield unfoldSaga({ handler, key: type });
}

function* getCertificateSaga({ type }: GetCertificate) {
  const handler = async () => API.get(ROUTES.CERTIFICATION);
  yield unfoldSaga({ handler, key: type });
}

function* updateCertificateSaga({ data, type }: UpdateCertificate) {
  const handler = async () => API.patch(`${ROUTES.CERTIFICATION}${data.id}/`, data);
  yield unfoldSaga({ handler, key: type });
}

function* deleteCertificateSaga({ data, type }: DeleteCertificate) {
  const handler = async () => API.delete(`${ROUTES.CERTIFICATION}${data.id}/`);
  yield unfoldSaga({ handler, key: type, payload: { id: data.id } });
}

function* getLanguageListSaga({ callbacks, type }: GetLanguageList) {
  const handler = async () => API.get(ROUTES.LANGUAGE);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getLanguageSaga({ type }: GetLanguage) {
  const handler = async () => API.get(ROUTES.CANDIDATE_LANGUAGE);
  yield unfoldSaga({ handler, key: type });
}

function* addLanguageSaga({ data, type }: AddLanguage) {
  const handler = async () => API.post(ROUTES.CANDIDATE_LANGUAGE, data);
  yield unfoldSaga({ handler, key: type });
}

function* updateLanguageSaga({ data, type }: UpdateLanguage) {
  const handler = async () => API.patch(`${ROUTES.CANDIDATE_LANGUAGE}${data.id}/`, data);
  yield unfoldSaga({ handler, key: type });
}

function* deleteLanguageSaga({ data, type }: DeleteLanguage) {
  const handler = async () => API.delete(`${ROUTES.CANDIDATE_LANGUAGE}${data.id}/`);
  yield unfoldSaga({ handler, key: type, payload: { id: data.id } });
}

function* addVolunteerSaga({ data, type }: AddVolunteer) {
  const handler = async () => API.post(ROUTES.VOLUNTEER, data);
  yield unfoldSaga({ handler, key: type });
}

function* getVolunteerSaga({ type }: GetVolunteer) {
  const handler = async () => API.get(ROUTES.VOLUNTEER);
  yield unfoldSaga({ handler, key: type });
}

function* updateVolunteerSaga({ data, type }: UpdateVolunteer) {
  const handler = async () => API.patch(`${ROUTES.VOLUNTEER}${data.id}/`, data);
  yield unfoldSaga({ handler, key: type });
}

function* deleteVolunteerSaga({ data, type }: DeleteVolunteer) {
  const handler = async () => API.delete(`${ROUTES.VOLUNTEER}${data.id}/`);
  yield unfoldSaga({ handler, key: type, payload: { id: data.id } });
}

function* getSkillListSaga({ data, callbacks, type }: GetSkillList) {
  const handler = async () => API.get(ROUTES.SKILL_LIST, { search: data.keyword });
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* submitContactUsSaga({ payload, callbacks, type }: SubmitContactUs) {
  const handler = async () => API.post(ROUTES.CONTACT_US, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* facebookLoginSaga({ payload, callbacks, type }: FacebookLogin) {
  const handler = async () => API.post(ROUTES.FACEBOOK_LOGIN, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* linkedInLoginSaga({ payload, callbacks, type }: LinkedInLogin) {
  const handler = async () => API.post(ROUTES.LINKEDIN_LOGIN, payload);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getInterestListSaga({ callbacks, type }: GetInterest) {
  const handler = async () => API.get(ROUTES.INTEREST_LIST);
  yield unfoldSaga({ handler, key: type }, callbacks);
}

function* getReferralDetailSaga() {
  const { data, ok } = yield call(() => API.get(ROUTES.REFERRAL_DETAIL));
  yield put(ok ? getReferralDetailSuccess(data) : getReferralDetailFailed(data));
}

function* addClicksSaga({ payload }: AddClicks) {
  yield call(() => API.post(ROUTES.ADD_CLICKS, payload));
}

function* rootSaga(): Generator {
  yield takeLatest(actionTypes.LOGIN, loginSaga);
  yield takeLatest(actionTypes.FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(actionTypes.REGISTER, registerSaga);
  yield takeLatest(actionTypes.VERIFY_EMAIL, verifyEmailSaga);
  yield takeLatest(actionTypes.CREATE_PASSWORD, createPasswordSaga);
  yield takeLatest(actionTypes.RESEND_CONFIRMATION_EMAIL, resendConfirmationEmailSaga);
  yield takeLatest(actionTypes.GET_ONBOARDING_REQUIREMENT, getOnboardingRequirementSaga);
  yield takeLatest(actionTypes.GET_MAJOR, getMajorSaga);
  yield takeLatest(actionTypes.GET_LIST_CV_TEMPLATE, getCvTemplateListSaga);
  yield takeLatest(actionTypes.GET_CV_TRANSACTION_LIST, getCvTransactionListSaga);
  yield takeLatest(actionTypes.EDIT_PROFILE, editProfileSaga);
  yield takeLatest(actionTypes.GET_PROFILE, getProfileSaga);
  yield takeLatest(actionTypes.CHECK_EMAIL_VERIFY, checkVerifyEmailSaga);
  yield takeLatest(actionTypes.BLOCK_ACCOUNT, blockAccountSaga);
  yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(actionTypes.SUBMIT_ONBOARDING, submitOnboardingSaga);
  yield takeLatest(actionTypes.GET_MY_CV_TEMPLATE_LIST, getMyCVTemplateListSaga);
  yield takeLatest(actionTypes.CREATE_MY_CV_TEMPLATE, createMyCVTemplateSaga);
  yield takeLatest(actionTypes.UPDATE_MY_CV_TEMPLATE, updateMyCVTemplateSaga);
  yield takeLatest(actionTypes.DELETE_MY_CV_TEMPLATE, deleteMyCVTemplateSaga);
  yield takeLatest(actionTypes.GET_EDUCATION, getEducationSaga);
  yield takeLatest(actionTypes.ADD_EDUCATION, addEducationSaga);
  yield takeLatest(actionTypes.UPDATE_EDUCATION, updateEducationSaga);
  yield takeLatest(actionTypes.REMOVE_EDUCATION, removeEducationSaga);
  yield takeLatest(actionTypes.GET_EXPERIENCE, getExperienceSaga);
  yield takeLatest(actionTypes.ADD_EXPERIENCE, addExperienceSaga);
  yield takeLatest(actionTypes.UPDATE_EXPERIENCE, updateExperienceSaga);
  yield takeLatest(actionTypes.REMOVE_EXPERIENCE, removeExperienceSaga);
  yield takeLatest(actionTypes.UPDATE_ORDERING, updateOrderingSaga);
  yield takeLatest(actionTypes.CANCEL_CV_TRANSACTION, cancelCvTransactionSaga);
  yield takeLatest(actionTypes.GET_TERM, getTermSaga);
  yield takeLatest(actionTypes.GET_POLICY, getPolicySaga);
  yield takeLatest(actionTypes.GET_SAMPLE_CV_LIST, getSampleCVSaga);
  yield takeLatest(actionTypes.GET_CV_CATEGORY_LIST, getCvCategoryListSaga);
  yield takeLatest(actionTypes.GET_CV_DETAIL_PAGE, getCvDetailPageSaga);
  yield takeLatest(actionTypes.GET_OCCUPATION_LIST, getOccupationListSaga);
  yield takeLatest(actionTypes.GET_OCCUPATION_DETAILS, getOccupationDetailsSaga);
  yield takeLatest(actionTypes.RESET_OCCUPATION_DETAILS, resetOccupationDetailsSaga);
  yield takeLatest(actionTypes.GET_SHARED_CV_DETAIL, getSharedCvDetails);
  yield takeLatest(actionTypes.LOGOUT, logoutSaga);
  yield takeLatest(actionTypes.GET_JOB_SCOPE_LIST, getJobScopeListSaga);
  yield takeLatest(actionTypes.GET_DEGREE_LIST, getDegreeListSaga);
  yield takeLatest(actionTypes.GET_SCHOOL_LIST, getSchoolListSaga);
  yield takeLatest(actionTypes.GET_JOB_TYPE_LIST, getJobTypeListSaga);
  yield takeLatest(actionTypes.GET_COMPANY_LIST, getCompanyListSaga);
  yield takeLatest(actionTypes.ADD_REFERENCE, addReferenceSaga);
  yield takeLatest(actionTypes.GET_REFERENCE, getReferenceSaga);
  yield takeLatest(actionTypes.UPDATE_REFERENCE, updateReferenceSaga);
  yield takeLatest(actionTypes.DELETE_REFERENCE, deleteReferenceSaga);
  yield takeLatest(actionTypes.ADD_CERTIFICATE, addCertificateSaga);
  yield takeLatest(actionTypes.GET_CERTIFICATE, getCertificateSaga);
  yield takeLatest(actionTypes.UPDATE_CERTIFICATE, updateCertificateSaga);
  yield takeLatest(actionTypes.DELETE_CERTIFICATE, deleteCertificateSaga);
  yield takeLatest(actionTypes.GET_LANGUAGE_LIST, getLanguageListSaga);
  yield takeLatest(actionTypes.GET_LANGUAGE, getLanguageSaga);
  yield takeLatest(actionTypes.ADD_LANGUAGE, addLanguageSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGE, updateLanguageSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGE, deleteLanguageSaga);
  yield takeLatest(actionTypes.ADD_VOLUNTEER, addVolunteerSaga);
  yield takeLatest(actionTypes.GET_VOLUNTEER, getVolunteerSaga);
  yield takeLatest(actionTypes.UPDATE_VOLUNTEER, updateVolunteerSaga);
  yield takeLatest(actionTypes.DELETE_VOLUNTEER, deleteVolunteerSaga);
  yield takeLatest(actionTypes.GET_INTEREST_LIST, getInterestListSaga);
  yield takeLatest(actionTypes.GET_SKILL_LIST, getSkillListSaga);
  yield takeLatest(actionTypes.SUBMIT_CONTACT_US, submitContactUsSaga);
  yield takeLatest(actionTypes.FACEBOOK_LOGIN, facebookLoginSaga);
  yield takeLatest(actionTypes.LINKEDIN_LOGIN, linkedInLoginSaga);
  yield takeLatest(actionTypes.GET_REFERRAL_DETAIL, getReferralDetailSaga);
  yield takeLatest(actionTypes.ADD_CLICKS, addClicksSaga);
}

export default rootSaga;
