import { css } from '@emotion/react';

export default css`
  body {
    .MuiContainer-root {
      padding-left: 24px;
      padding-right: 24px;
    }

    .MuiSnackbar-root {
      .MuiAlert-icon {
        align-items: center;
      }
      .MuiAlert-message {
        white-space: pre;
      }
    }

    .MuiPopover-root {
      overflow-y: scroll;
    }

    .MuiDialog-paper {
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #dcdcdc;
      }
    }

    .MuiOutlinedInput-root.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }

    .MuiInputBase-root.Mui-disabled {
      color: #212121;
      cursor: default;
      background: #eeeeee;
      border: 1px solid #eeeeee;
    }
  }
`;
