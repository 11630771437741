import { css } from '@emotion/react';

export default css`
  .c-input {
    input {
      font-size: 14px;
      padding: 16px 14px;

      &::placeholder {
        font-style: normal;
        color: #9e9e9e;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #eeeeee;
    }
    .MuiOutlinedInput-root {
      border-radius: 8px;
      background: white;
    }
    .MuiOutlinedInput-root:hover,
    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #478df2 !important;
        border-width: 1px !important;
      }
    }
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 6.5px;
    }
  }

  .c-cv-template-item {
    box-shadow: 0px 10px 10px rgba(194, 194, 194, 0.1);
    border-radius: 8px;
    height: 380px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .c-cv-template-item__top {
      background: #f3f6fb;
      text-align: center;

      height: 200px;
      overflow: hidden;
      padding: 16px 30px 0px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      img {
        width: 100%;
        height: 'auto';
      }
    }
    .c-cv-template-item__bot__btn {
      transform: translate(50%, 30px);
    }

    button {
      width: 120px;
      height: 36px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #478df2;
      border-radius: 120px;
    }
  }

  .c-input-text {
    width: 100%;
    margin-top: 36px;
    .c-input-text__input {
      width: 100%;
      height: 48px;
      border-radius: 8px;
      background: #ffffff;
      border: 1px solid #f5f6f8;
      padding: 0px 12px;

      &::placeholder {
        color: #9e9e9e;
      }
    }
  }

  .c-input-checkbox {
    path {
      fill: #e0e0e0;
    }

    .Mui-checked {
      path {
        fill: inherit;
      }
    }
  }

  .c-login-panel-slider {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100%;

    .slick-slider {
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .slick-dots li button:before {
      background: #ffffff;
      width: 12px;
      height: 12px;
      content: '';
      border-radius: 100px;
      opacity: 1;
    }

    .slick-dots li.slick-active button:before {
      opacity: 1;
      background: #ff792e;
    }

    .slick-track {
      display: flex;
      align-items: flex-end;
    }
  }

  .c-login-panel-slider-item {
    padding: 0px 92px;
    text-align: center;

    .c-login-panel-slider-item__img {
      flex-grow: 1;
    }
  }

  .m-vertical-step {
    .m-vertical-step__stepper {
      border-radius: 8px;

      .MuiStepConnector-vertical {
        margin-left: 17px;
        padding: 0px;
      }

      .m-vertical-step__stepper__circle {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background: #ffffff;
        color: #484545;
        border: 1px solid #478df2;
      }

      .m-vertical-step__stepper__text {
        color: #212121;
      }

      .Mui-disabled {
        .m-vertical-step__stepper__circle {
          border: 1px solid #e0e0e0;
          color: #e0e0e0;
          font-weight: 700;
        }

        .m-vertical-step__stepper__text {
          color: #e0e0e0;
        }

        .m-vertical-step__stepper__link {
          &:hover {
            color: black;
          }
        }
      }

      .MuiStep-completed {
        .m-vertical-step__stepper__circle {
          background: #478df2;
          color: #ffffff;
          border: 1px solid #478df2;
        }

        .m-vertical-step__stepper__text {
          color: #478df2;
        }
      }

      .MuiStepConnector-lineVertical {
        min-height: 38px;
      }

      .MuiStepConnector-completed,
      .MuiStepConnector-active {
        .MuiStepConnector-lineVertical {
          border-left-width: 2px;
          border-color: #ff792e;
        }
      }
    }
  }

  .c-review-card {
    width: 100%;
    height: 100%;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 5.72477px 22.8991px rgba(194, 194, 194, 0.25);
    border-radius: 0px 20px;

    @media (min-width: 960px) {
      padding: 40px 48px;
      box-shadow: 0px 10px 40px rgba(194, 194, 194, 0.25);
      border-radius: 0px 40px;
    }

    .c-review-card__name {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;

      @media (min-width: 960px) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    .c-review-card__rating {
      display: flex;
      justify-content: space-between;
      font-size: 6px;

      .MuiRating-root {
        color: #ff792e;
      }

      .MuiRating-sizeSmall {
        font-size: 8px;
      }

      @media (min-width: 960px) {
        font-size: 8px;

        .MuiRating-sizeSmall {
          font-size: 11px;
        }
      }
    }
  }

  .c-plan-card {
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 10px 10px rgba(194, 194, 194, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .c-plan-card__title {
      font-weight: bold;
      font-size: 36px;
      line-height: 49px;
      letter-spacing: 0.25px;
      color: #ff792e;
    }

    .c-plan-card__desc {
      margin-top: 14px;
      color: #757575;
    }

    .c-plan-card__service {
      margin-top: 14px;
      display: flex;
      align-items: center;
    }

    .c-plan-card__line {
      border: 1px dashed #9e9e9e;
      margin: 26px 16px;
    }

    .c-plan-card__money {
      display: flex;
      align-items: center;
    }

    .c-plan-card__price {
      font-weight: bold;
      font-size: 48px;
      line-height: 65px;
      margin-right: 10px;
    }
  }

  .c-tabs {
    min-height: 36px !important;
    .MuiTab-textColorInherit.Mui-selected {
      background: #274977;
      color: #ffffff;
    }
    .MuiTab-textColorInherit {
      opacity: 1;
      border-radius: 40px;
      padding: 10px 20px;
      min-height: 36px;
    }
    .MuiTab-root {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #212121;
      text-transform: none;
    }
    .MuiTabs-flexContainer {
      justify-content: center;
    }
  }

  .m-text-field {
    min-height: 100px;
    .MuiFormHelperText-root.Mui-error {
      color: #212121;
      margin: 3px 0px 0px;
    }
    &--no-height {
      min-height: unset;
    }
  }

  .m-select {
    .MuiFormHelperText-root.Mui-error {
      color: #212121;
      margin: 3px 0px 0px;
    }
  }

  .m-language-menu {
    box-shadow: 0px 6px 25px #c2c2c240;
    border-radius: 8px;

    .MuiMenu-list {
      min-width: 84px;
    }

    .MuiMenu-paper {
      box-shadow: 0px 6px 25px #c2c2c240;
    }
  }

  .m-btn-menu {
    path {
      fill: #212121;
    }
    &:hover {
      text-decoration: none;
      background-color: #c8d0e0;
    }
  }

  .m-button {
    .m-button__outlined-btn {
      border-radius: 25px;
      padding: 6px 24px;
      font-size: 14px;
      background-color: white;
      text-transform: none;

      &.m-button__outlined-btn--blue {
        border: 1px solid #478df2;
        color: #478df2;
      }
      &.m-button__outlined-btn--gray {
        border: 1px solid #c8d0e0;
      }

      @media (min-width: 960px) {
        padding: 6px 50px;
        font-size: 16px;
      }
    }

    .m-button__blue-bkg-btn {
      background-color: #478df2;
      border-radius: 25px;
      color: white;
      padding: 6px 24px;
      font-size: 14px;
      text-transform: none;

      &:hover {
        background-color: #6fa5f0;
      }

      @media (min-width: 960px) {
        padding: 6px 50px;
        font-size: 16px;
      }
    }
  }

  .m-button-social {
    & span {
      display: flex;
    }

    .m-button-social__facebook {
      white-space: nowrap;
      background-color: #1977f3;
      color: #fff;
      border: none;

      &.m-button-social__facebook--large {
        font-size: 12px;
        line-height: 21px;
        border-radius: 40px;
        padding: 14px 16px;
        width: 100%;
        min-width: 198px;
        margin: 0px 3px;

        & .fa {
          margin-right: 16px;
        }
      }

      &.m-button-social__facebook--small {
        border-radius: 50%;
        width: 21px;
        height: 21px;

        i {
          transform: translate(-1px, 1px);
        }
      }
    }

    .m-button-social__linkedin {
      white-space: nowrap;
      border: none;
      text-transform: none;

      &.m-button-social__linkedin--large {
        background-color: #007ab9;
        color: #fff;
        font-size: 12px;
        line-height: 21px;
        border-radius: 40px;
        padding: 14px 16px;
        min-width: 198px;
        margin: 0px 3px;

        &:hover {
          background-color: #036597;
        }
      }

      &.m-button-social__linkedin--small {
        min-width: 24px;
        padding: 0;
      }
    }

    @media (max-width: 960px) {
      > span,
      .m-button-social__facebook--large,
      .m-button-social__linkedin--large {
        width: 100% !important;
      }
    }
  }

  .m-picture-upload {
    .m-picture-upload__avatar {
      object-fit: cover;
    }
    .m-picture-upload--error {
      border: 1px solid #de1306 !important;
    }
  }

  .c-faq-item a {
    color: inherit;
  }

  .m-phone-input {
    .m-phone-input__invalid .react-tel-input {
      border-color: #ff1744;
    }

    .react-tel-input {
      border-radius: 8px;
      border: 1px solid #eeeeee;

      .form-control {
        width: 100%;
        height: 49px;
        border: none;
        padding-left: 60px;
        border-radius: 8px;
      }

      .flag-dropdown {
        height: 49px;
        border: none;
        border-radius: 8px 0 0 8px !important;
      }

      .country-list {
        border-radius: 8px;
        box-shadow: 4px 2px 18px rgb(0 0 0 / 15%);
        .search-box {
          border: 1px solid #eeeeee;
          width: calc(100% - 56px);
        }
      }

      .selected-flag {
        width: 49px;
        border-radius: 8px 0 0 8px !important;
        border-right: 1px solid #eeeeee;
        .flag {
          left: 50%;
          transform: translateX(calc(-50% - 3px));
        }
      }
    }
  }

  .m-cv-image {
    &--overlay {
      button {
        width: 80%;
        max-width: 249px;
        padding: 9px 48px !important;
        white-space: nowrap;
      }
      .u-absolute-0 {
        display: none;
      }
      &:hover .u-absolute-0 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &__modal {
      background: #e0e0e0;
      opacity: 0.5;
      backdrop-filter: blur(15px);
    }
  }

  .m-header {
    min-height: 64px !important;
    height: 64px !important;

    button {
      height: 36px !important;
    }
  }
`;
