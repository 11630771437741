import { css } from '@emotion/react';

export default css`
  .home-header {
    background: linear-gradient(180deg, #deebff -63.32%, rgba(255, 255, 255, 0) 100%);
  }

  .home-hero-desc {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.5px;
    color: #484545;
  }

  .home-hero-images-wrap {
    position: relative;
    height: 333px;

    @media (min-width: 960px) {
      height: 42vw;
      max-height: 600px;
    }
  }

  .home-hero-vector {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 25%;
    right: 0;
    z-index: 0;
  }

  .home-hero-cv {
    width: 56%;
    height: 100%;
    position: absolute;
    right: 8%;
    z-index: 1;

    @media (min-width: 960px) {
      width: 65%;
    }
  }

  .home-hero-cv-card {
    position: absolute;
    top: 40%;
    left: 15%;
    box-shadow: 0px 6.34211px 25.3684px rgba(194, 194, 194, 0.25);
    background: #ffffff;
    z-index: 10;
    width: 128px;
    padding: 6px;
    border-radius: 4px;

    @media (min-width: 960px) {
      width: 240px;
      padding: 10px;
      border-radius: 8px;
    }

    .home-hero-cv-card__rating {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #757575;
      font-size: 4px;

      .MuiRating-root {
        color: #ff792e;
      }

      .MuiRating-sizeSmall {
        font-size: 5px;
      }

      @media (min-width: 960px) {
        font-size: 8px;

        .MuiRating-sizeSmall {
          font-size: 11px;
        }
      }
    }

    .home-hero-cv-card__name {
      margin-top: 4px;
      font-size: 8px;
      font-weight: 600;
      line-height: 1.5;

      @media (min-width: 960px) {
        margin-top: 6px;
        font-size: 15px;
      }
    }

    .home-hero-cv-card__job {
      font-size: 5px;
      line-height: 7px;
      color: #757575;

      @media (min-width: 960px) {
        font-size: 9px;
        line-height: 13px;
      }
    }

    .home-hero-cv-card__desc {
      margin-top: 4px;
      font-size: 5px;
      line-height: 7px;
      color: #484545;

      @media (min-width: 960px) {
        margin-top: 8px;
        font-size: 9px;
        line-height: 13px;
      }
    }
  }

  .home-excellent {
    background: #f7fbff;

    @media (min-width: 960px) {
      height: 300px;
    }

    .home-excellent-content {
      position: relative;
    }

    .home-excellent-title {
      @media (min-width: 960px) {
        display: none;
      }

      @media (min-width: 1200px) {
        display: inline;
      }
    }

    .home-excellent__desc {
      margin-top: 10px;
      font-size: 18px;
      line-height: 27px;
      color: #484545;
    }
  }

  .home-weaponize {
    .home-hero-cv {
      left: 17.5%;
      right: auto;

      @media (min-width: 960px) {
        left: 8%;
      }
    }
    .home-hero-vector {
      left: 0;
      top: 10%;
      width: 100%;

      @media (min-width: 960px) {
        top: 25%;
        width: 80%;
      }
    }

    .home-weaponize-card {
      margin-top: 24px;
      padding: 16px;

      .home-weaponize-card__desc {
        margin-top: 12px;
        line-height: 21px;
        letter-spacing: 0.25px;
        color: #484545;
      }
    }

    .home-weaponize-card--paper {
      box-shadow: 0px 4px 40px rgba(194, 194, 194, 0.2);
    }
  }

  .home-plans {
    background-color: #f7fbff;

    .home-plan-card {
      background: linear-gradient(163.37deg, #4b91f7 0%, #1c6ac6 99.99%);
      box-shadow: 0px 10px 10px rgba(194, 194, 194, 0.1);
      border-radius: 20px;
      padding: 40px 40px 0;
      color: #ffffff;

      .home-plan-card__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.5px;
        margin-bottom: 16px;

        @media (min-width: 960px) {
          font-size: 32px;
          line-height: 48px;
        }
      }
    }
  }

  .home-why-urban {
    .home-hero-cv {
      right: 17.5%;
      left: auto;

      @media (min-width: 960px) {
        right: 8%;
      }
    }

    .home-hero-vector {
      right: 0;
      top: 10%;
      width: 100%;

      @media (min-width: 960px) {
        top: 25%;
        width: 80%;
      }
    }
  }
`;
