import { Global } from '@emotion/react';
// generic
import normalize from './generic/_generic.normalize';
import boxSizing from './generic/_generic.box-sizing';
import reset from './generic/_generic.reset';

// elements
import elements from './elements/_elements.commons';

// objects
import objectsCommons from './objects/_objects.commons';
import objectsTypography from './objects/_objects.typography';

// Component
import override from './components/_components.override';
import commons from './components/_components.commons';
import molecules from './components/_components.molecules';
import organisms from './components/_components.organisms';
import pages from './components/_components.pages';
import header from './components/_header';
import footer from './components/_footer';
import home from './components/_home';

// Utilities
import utilities from './utilities/_utilities.commons';

function Style(): JSX.Element {
  return (
    <>
      <Global styles={normalize} />
      <Global styles={boxSizing} />
      <Global styles={reset} />

      <Global styles={elements} />

      <Global styles={objectsCommons} />
      <Global styles={objectsTypography} />

      <Global styles={override} />
      <Global styles={commons} />
      <Global styles={molecules} />
      <Global styles={organisms} />
      <Global styles={pages} />
      <Global styles={header} />
      <Global styles={footer} />
      <Global styles={home} />

      <Global styles={utilities} />
    </>
  );
}

export default Style;
