import { css } from '@emotion/react';

export default css`
  .c-sample-side-filter {
    box-shadow: 0px 10px 10px rgba(194, 194, 194, 0.1);

    .c-sample-side-filter__divider {
      padding: 8px 8px 0px;
    }
  }

  .c-sample-sidebar {
    .c-sample-sidebar__title,
    .c-sample-sidebar__sort,
    .c-sample-sidebar__item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 8px;
      width: 100%;
      &.MuiToggleButton-root {
        color: black;
        border: none;
        text-transform: none;
      }
      &.Mui-selected {
        background-color: #f7fbff;
        color: #00447c;
        font-weight: 600;
      }
      &.Mui-expanded {
        min-height: 57px;
      }
      .MuiTypography-body1 {
        font-size: 14px;
      }
      &.c-sample-sidebar__item--selected {
        .MuiTypography-body1 {
          color: #00447c;
          font-weight: 600;
        }
      }
    }

    .c-sample-sidebar__title {
      background: #f5f6f8;
    }

    .c-sample-sidebar__accordion__item {
      height: 57px;
      padding: 0px 8px;
      &.Mui-expanded {
        min-height: 57px;
      }
      &.MuiAccordionSummary-root {
        min-height: unset;
      }
      @media (min-width: 960px) {
        pointer-events: none;
        font-size: 18px !important;
        height: 40px;
      }
    }

    .MuiToggleButtonGroup-root {
      display: block;
    }
  }

  .c-sample-paginate {
    .MuiPaginationItem-outlined {
      font-weight: 600;
      border-radius: 4px;
      border: 1px solid #eeeeee;
    }

    .Mui-selected {
      border: 1px solid #478df2 !important;
      background-color: white !important;
      color: #478df2;
    }

    .MuiSvgIcon-root {
      fill: #478df2;
    }
  }

  .c-cv-templates {
    margin-inline: -24px;
    .c-cv-templates__item {
      margin: 20px;
    }
  }

  .c-sample-cv {
    .c-sample-cv__input {
      height: 56px;
      width: 100%;
      max-width: 360px;
      border-radius: 4px;
      border: 1px solid #eeeeee;
      padding-left: 11px;
      margin-top: 28px;

      & input {
        border: none;
        width: 100%;

        &::placeholder {
          font-style: normal;
          color: #9e9e9e;
        }
      }
    }
  }

  .o-color-picker {
    overflow-y: auto;
    .o-color-picker__button {
      background-color: #478df2;
      color: white;
      border-radius: 25px;
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 20px;

      &:hover {
        background-color: #49a2ffb0;
      }
    }
  }

  .o-shared-cv {
    overflow-y: auto;
    .o-shared-cv__button {
      background-color: #478df2;
      color: white;
      border-radius: 25px;
      padding-left: 40px;
      padding-right: 40px;

      &:hover {
        background-color: #49a2ffb0;
      }
    }

    .m-button__outlined-btn {
      width: 100%;
      max-width: 280px;
      padding: 6px 8px;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .o-base-cv {
    background-color: white;
    width: 38.3em;
    max-height: 54.164em;
    min-height: 54.164em;
    white-space: normal;
    overflow: auto;

    &.o-base-cv__multiple__page {
      max-height: unset;
    }

    @media only screen and (max-width: 960px) {
      width: 100%;
    }

    .o-base-cv__avatar {
      object-fit: cover;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dcdcdc;
    }

    .ql-editor {
      overflow: hidden;
    }
  }

  .ql-editor {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      word-break: break-word !important;
    }

    &.ql-editor--content {
      word-break: break-word !important;
      padding: 0;
      height: auto;

      p {
        line-height: 1.4;
      }

      strong {
        font-weight: bold;
      }

      ol,
      ul {
        padding-left: 0.2em;
      }
    }
  }

  .o-free-0001 {
    .o-free-0001__divider {
      margin: 0 0 0.4em;
    }
  }

  .o-premium-0001 {
    .o-premium-0001__sideinfo img {
      width: 1.5em;
    }

    .o-premium-0001__name-line {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
  }

  .o-premium-0004 {
    .o-premium-0004__rotate {
      transform: rotatez(270deg);
    }
  }

  .o-premium-0008 {
    .o-premium-0008__imginfo,
    .o-premium-0008__imgsocial {
      max-width: 67%;
      max-height: 67%;
      width: 100%;
    }
  }

  .o-premium-0009 {
    .o-premium-0009__wrapper {
      & > :last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  .o-onboarding-menu {
    .MuiToolbar-regular.MuiToolbar-gutters,
    & + .MuiToolbar-regular.MuiToolbar-gutters {
      min-height: 56px;
    }

    .o-onboarding-menu__img {
      cursor: pointer;
      @media (min-width: 960px) {
        width: 150px;
      }
    }
  }

  .o-onboarding-skill-preview {
    .MuiLinearProgress-root {
      height: 8px;
      border-radius: 4px;
    }
  }

  .o-my-cv-card {
    .o-my-cv-card__bottom-bar {
      .MuiButton-text {
        padding: 0px 5px 0px;
      }
    }

    .o-my-cv-card__small-status {
      flex: 1;
    }

    .o-my-cv-card__small-button {
      font-size: 8px;
      text-transform: none;
      .MuiButton-label {
        height: 100%;
        flex-direction: column;
      }
    }
  }

  .o-checkout-radio {
    .MuiRadio-root {
      padding: 0 9px;
      align-self: flex-start;
    }
  }

  .o-setting-transaction {
    min-width: 650px;

    .MuiTableCell-root {
      padding: 10px;
      border-bottom: none;
    }

    .MuiTableHead-root .MuiTableCell-root {
      font-size: 16px;
    }

    .MuiTableBody-root .MuiTableRow-root {
      &:hover {
        background-color: #f7fbff;
      }
    }
  }

  .o-my-cv-card,
  .o-download-dialog {
    .o-base-cv {
      overflow: hidden;
    }
  }

  .o-download-dialog {
    .o-base-cv {
      overflow: hidden;

      .m-cv-watermark {
        top: 52em !important;
        bottom: auto !important;
      }
    }
  }

  .o-custom-scroll {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dcdcdc;
    }
  }

  .o-btn-outline-primary,
  .MuiButton-outlined.o-btn-outline-primary {
    border: 1px solid #478df2;
    border-radius: 25px;
    padding: 8px 16px;
    background-color: white;
    color: #478df2;
    width: 100%;
    max-width: 208px;
    font-weight: 600;
  }
`;
