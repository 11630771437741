import { css } from '@emotion/react';

export default css`
  #footer {
    background-color: #00447c;

    #footer-nav {
      display: flex;
      flex-direction: column;

      @media (min-width: 960px) {
        display: block;
      }
    }

    .footer-content {
      color: #ffffff;

      @media (min-width: 960px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .MuiTypography-body1 {
        font-size: 14px;
      }

      .MuiIconButton-root {
        margin-left: 12px;
      }
    }
  }
`;
