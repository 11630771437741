import { HYDRATE } from 'next-redux-wrapper';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { revokeData, setCookieAccessToken, setCookieCvSelected } from 'utils/cookie';
import { convertProfileDataToCvInfo, convertProfileDataToProfileInfo } from 'helpers';
import { Action, AppState, MyCVTemplateData, Profile, actionTypes } from './interfaces';

const userInfo = {
  token: '',
  expired_at: '',
  user: {
    email: '',
    type: null,
    onboarding_urbancv: false,
    company_id: null,
  },
};

const profileInfo = {
  username: '',
  avatar: '',
  email: '',
  first_name: '',
  last_name: '',
  language: '',
  last_change_password: '',
  mobile_number: '',
  onboarding: false,
  onboarding_urbancv: false,
  phone_country_code: '',
  pk: '',
  referral_code: '',
  skype: '',
  social_login: '',
  socket: '',
  type: 0,
  verified: false,
  show_avatar: true,
  show_proficiency: true,
};

const storedCvInfo = {
  basicInfo: {
    avatar: '',
    first_name: '',
    last_name: '',
    role: '',
    date_of_birth: null,
    address: '',
    nationality: '',
    country: '',
    city: '',
    phone_country_code: '',
    mobile_number: '',
    cv_email: '',
    skype: '',
    summary: '',
    socialMedia: [],
    show_avatar: true,
    show_proficiency: true,
  },
  experience: [],
  education: [],
  skills: [],
  reference: [],
  interest: [],
  certifications: [],
  languages: [],
  volunteers: [],
};

export const initialState: AppState = {
  error: null,
  username: '',
  userInfo,
  profileInfo,
  storedCvInfo,
  cvSettings: {
    currentCvColor: '#2C77D7',
    currentCvFont: 'Open Sans',
    currentCvFontSize: 16,
    isShowAvatar: true,
    isShowProficiency: true,
  },
  cvSelected: {},
  checkoutTransactionId: null,
  isGotAllInformation: false,
  isAddEducationLoading: false,
  isAddExperienceLoading: false,
  isAddReferenceLoading: false,
  isAddCertificateLoading: false,
  isAddLanguageLoading: false,
  isAddVolunteerLoading: false,
  isUpdatingCvData: false,
  isProfileLoading: false,
  isEditProfileLoading: false,
  isSubmittingOnboarding: false,
  isSubmitOnboardingSuccess: false,
  isEditProfileSuccess: false,
  isCreateMyCVTemplateLoading: false,
  isGetCvTransactionListLoading: false,
  isChangePasswordLoading: false,
  isChangePasswordSuccess: false,
  isGetMyCVTemplateListLoading: true,
  isGetSampleCVLoading: false,
  isOccupationDetailsLoading: false,
  isOccupationListLoading: false,
  isJobScopeListLoading: false,
  isSchoolListLoading: false,
  isDegreeListLoading: false,
  isJobTypeListLoading: false,
  isCompanyListLoading: false,
  onboardingHeader: null,
  verifyEmailResponse: null,
  onboardingRequirementResponse: null,
  getMajorResponse: null,
  createMyCVTemplateResponse: null,
  updateMyCVTemplateResponse: null,
  deleteMyCVTemplateResponse: null,
  myCVTemplateList: [],
  cvTransactionList: [],
  term: {
    urbancv_tnc: '',
  },
  policy: {
    privacy_policy_urbancv: '',
  },
  sampleCVList: [],
  cvCategoryList: [],
  cvDetailPage: {
    id: 0,
    code: '',
    name: '',
    is_premium: true,
    image: '',
    price: 0,
    permalink: '',
  },
  isGetCvDetailLoading: false,
  occupationList: [],
  occupationDetails: {
    id: 0,
    about_me: '',
    name: '',
    skill: [],
    work_exp_desc: '',
    job_scopes: [],
  },
  sharedCVDetails: {
    ...storedCvInfo,
    color: '',
    font: '',
    font_size: null,
    show_avatar: true,
    show_proficiency: true,
    language: '',
    name: '',
    id: 0,
    created_date: '',
    template: {
      code: '',
    },
  },
  isSharedCVDetailsLoading: false,
  jobScopeList: [],
  degreeList: [],
  schoolList: [],
  jobTypeList: [],
  companyList: [],
  referralDetail: {
    clicks: 0,
    referral_code: '',
    signups: 0,
    signups_verified: 0,
  },
};

const reducer = (state = initialState, action: Action | { type: typeof HYDRATE; payload: AppState }): AppState => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload };

    case actionTypes.LOGIN_SUCCESS: {
      setCookieAccessToken({ token: action.data.token, expireAt: action.data.expired_at });
      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          basicInfo: {
            ...state.storedCvInfo.basicInfo,
            cv_email: action.data.user.email,
          },
        },
        userInfo: action.data,
      };
    }

    case actionTypes.GET_PROFILE:
      return { ...state, ...{ isProfileLoading: true } };

    case actionTypes.GET_PROFILE_SUCCESS: {
      if (action.data.isGetFullProfile) {
        return {
          ...state,
          ...{
            isGotAllInformation: true,
            profileInfo: convertProfileDataToProfileInfo(action.data),
            username: action.data.username,
            storedCvInfo: convertProfileDataToCvInfo(action.data),
            isProfileLoading: false,
          },
        };
      }
      return {
        ...state,
        ...{
          profileInfo: convertProfileDataToProfileInfo(action.data),
          isProfileLoading: false,
        },
      };
    }

    case actionTypes.GET_PROFILE_FAILED:
      return { ...state, ...{ error: action.error, isProfileLoading: false } };

    case actionTypes.EDIT_PROFILE:
      return { ...state, ...{ isEditProfileLoading: true } };

    case actionTypes.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        ...{ profileInfo: action.data, isEditProfileSuccess: true, isEditProfileLoading: false },
      };

    case actionTypes.EDIT_PROFILE_FAILED:
      return { ...state, ...{ isEditProfileSuccess: false, isEditProfileLoading: false } };

    case actionTypes.RESET_STORE:
      revokeData();
      return { ...state, userInfo, profileInfo, storedCvInfo, username: '', isGotAllInformation: false };

    case actionTypes.REGISTER_SUCCESS_ACTION: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...pick(action.data, ['token', 'expired_at']),
          user: { ...pick(action.data, ['email', 'type']), ...state.userInfo.user },
        },
        profileInfo: {
          ...state.profileInfo,
          ...pick(action.data, ['email', 'last_name', 'first_name']),
          onboarding_urbancv: false,
        },
      };
    }

    case actionTypes.VERIFY_EMAIL_SUCCESS:
      return { ...state, ...{ verifyEmailResponse: action.data } };

    case actionTypes.GET_ONBOARDING_REQUIREMENT_SUCCESS:
      return { ...state, ...{ onboardingRequirementResponse: action.data } };

    case actionTypes.SAVE_CV_INFO:
      return { ...state, ...{ storedCvInfo: action.data } };

    case actionTypes.GET_MAJOR_SUCCESS:
      return { ...state, ...{ getMajorResponse: action.data } };

    case actionTypes.SET_CV_TRANSACTION_ID:
      return { ...state, ...{ checkoutTransactionId: action.data } };

    case actionTypes.CANCEL_CV_TRANSACTION_SUCCESS:
    case actionTypes.CANCEL_CV_TRANSACTION_FAILED:
      return { ...state, ...{ checkoutTransactionId: null } };

    case actionTypes.CHANGE_PASSWORD:
      return { ...state, ...{ isChangePasswordLoading: true } };

    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        ...{ isChangePasswordLoading: false, isChangePasswordSuccess: true },
      };

    case actionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        ...{ isChangePasswordLoading: false, isChangePasswordSuccess: false, error: action.error },
      };

    case actionTypes.SET_CV_SELECTED: {
      setCookieCvSelected(JSON.stringify(action.data));
      return { ...state, cvSelected: action.data };
    }

    case actionTypes.SET_CURRENT_CV_COLOR:
      return { ...state, cvSettings: { ...state.cvSettings, currentCvColor: action.data } };

    case actionTypes.SET_CURRENT_CV_FONT:
      return { ...state, cvSettings: { ...state.cvSettings, currentCvFont: action.data } };

    case actionTypes.SET_CURRENT_CV_FONT_SIZE:
      return { ...state, cvSettings: { ...state.cvSettings, currentCvFontSize: action.data } };

    case actionTypes.SET_SHOW_PROFICIENCY:
      return { ...state, cvSettings: { ...state.cvSettings, isShowProficiency: action.data } };

    case actionTypes.SET_SHOW_AVATAR:
      return { ...state, cvSettings: { ...state.cvSettings, isShowAvatar: action.data } };

    case actionTypes.GET_MY_CV_TEMPLATE_LIST:
      return { ...state, isGetMyCVTemplateListLoading: true };

    case actionTypes.GET_MY_CV_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        ...{ myCVTemplateList: action.data, isGetMyCVTemplateListLoading: false },
      };

    case actionTypes.GET_MY_CV_TEMPLATE_LIST_FAILED:
      return {
        ...state,
        ...{ error: action.error, isGetMyCVTemplateListLoading: false },
      };

    case actionTypes.GET_CV_TRANSACTION_LIST:
      return { ...state, ...{ isGetCvTransactionListLoading: true } };

    case actionTypes.GET_CV_TRANSACTION_LIST_SUCCESS: {
      return {
        ...state,
        ...{ cvTransactionList: action.data, isGetCvTransactionListLoading: false },
      };
    }

    case actionTypes.GET_CV_TRANSACTION_LIST_FAILED:
      return { ...state, ...{ error: action.error, isGetCvTransactionListLoading: false } };

    case actionTypes.CREATE_MY_CV_TEMPLATE:
      return { ...state, ...{ isCreateMyCVTemplateLoading: true } };

    case actionTypes.CREATE_MY_CV_TEMPLATE_SUCCESS:
      return {
        ...state,
        ...{ createMyCVTemplateResponse: action.data, isCreateMyCVTemplateLoading: false },
      };

    case actionTypes.CREATE_MY_CV_TEMPLATE_FAILED:
      return { ...state, ...{ error: action.error, isCreateMyCVTemplateLoading: false } };

    case actionTypes.UPDATE_MY_CV_TEMPLATE_SUCCESS:
      return { ...state, ...{ updateMyCVTemplateResponse: action.data } };

    case actionTypes.DELETE_MY_CV_TEMPLATE_SUCCESS:
      return { ...state, ...{ deleteMyCVTemplateResponse: action.data } };

    case actionTypes.VERIFY_EMAIL_FAILED:
    case actionTypes.GET_MAJOR_FAILED:
    case actionTypes.GET_ONBOARDING_REQUIREMENT_FAILED:
    case actionTypes.GET_TERM_FAILED:
    case actionTypes.GET_POLICY_FAILED:
    case actionTypes.UPDATE_ORDERING_FAILED:
    case actionTypes.GET_EDUCATION_FAILED:
    case actionTypes.GET_EXPERIENCE_FAILED:
    case actionTypes.UPDATE_MY_CV_TEMPLATE_FAILED:
    case actionTypes.DELETE_MY_CV_TEMPLATE_FAILED:
      return { ...state, ...{ error: action.error } };

    case actionTypes.GET_EXPERIENCE_SUCCESS:
      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          experience: action.data,
        },
      };

    case actionTypes.ADD_EXPERIENCE: {
      return { ...state, isAddExperienceLoading: true };
    }

    case actionTypes.ADD_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        isAddExperienceLoading: false,
        storedCvInfo: {
          ...state.storedCvInfo,
          experience: [...state.storedCvInfo.experience, action.data],
        },
      };
    }

    case actionTypes.ADD_EXPERIENCE_FAILED: {
      return { ...state, isAddExperienceLoading: false, error: action.error };
    }

    case actionTypes.UPDATE_EXPERIENCE_SUCCESS: {
      const editedExperience = { ...action.data };
      const editedCvExperience = [...state.storedCvInfo.experience];
      const editingExpIndex = editedCvExperience.findIndex((item) => item.id === editedExperience.id);

      if (editingExpIndex >= 0) {
        editedCvExperience[editingExpIndex] = editedExperience;
      } else {
        editedCvExperience.push(editedExperience);
      }

      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          experience: editedCvExperience,
        },
      };
    }

    case actionTypes.UPDATE_EXPERIENCE_ORDERING:
      return {
        ...state,
        storedCvInfo: { ...state.storedCvInfo, experience: action.data },
      };

    case actionTypes.REMOVE_EXPERIENCE: {
      return { ...state, isUpdatingCvData: true };
    }

    case actionTypes.REMOVE_EXPERIENCE_SUCCESS: {
      const removedCvExperience = [...state.storedCvInfo.experience];
      const removeExpIndex = removedCvExperience.findIndex((item) => item.id === action.data.id);

      if (removeExpIndex >= 0) {
        removedCvExperience.splice(removeExpIndex, 1);
      }

      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          experience: removedCvExperience,
        },
        isUpdatingCvData: false,
      };
    }

    case actionTypes.REMOVE_EXPERIENCE_FAILED: {
      return { ...state, isUpdatingCvData: false };
    }

    case actionTypes.GET_EDUCATION_SUCCESS:
      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          education: action.data,
        },
      };

    case actionTypes.ADD_EDUCATION: {
      return { ...state, isAddEducationLoading: true };
    }
    case actionTypes.ADD_EDUCATION_SUCCESS: {
      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          education: [...state.storedCvInfo.education, action.data],
        },
        isAddEducationLoading: false,
      };
    }
    case actionTypes.ADD_EDUCATION_FAILED: {
      return {
        ...state,
        error: action.error,
        isAddEducationLoading: false,
      };
    }

    case actionTypes.UPDATE_EDUCATION_SUCCESS: {
      const editedEducation = { ...action.data };
      const editedCvEducation = [...state.storedCvInfo.education];
      const editingEduIndex = editedCvEducation.findIndex((item) => item.id === editedEducation.id);

      if (editingEduIndex >= 0) {
        editedCvEducation[editingEduIndex] = editedEducation;
      } else {
        editedCvEducation.push(editedEducation);
      }

      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          education: editedCvEducation,
        },
      };
    }

    case actionTypes.UPDATE_EDUCATION_ORDERING:
      return {
        ...state,
        storedCvInfo: { ...state.storedCvInfo, education: action.data },
      };

    case actionTypes.REMOVE_EDUCATION: {
      return { ...state, isUpdatingCvData: true };
    }

    case actionTypes.REMOVE_EDUCATION_SUCCESS: {
      const removedCvEducation = [...state.storedCvInfo.education];
      const removeEduIndex = removedCvEducation.findIndex((item) => item.id === action.data.id);

      if (removeEduIndex >= 0) {
        removedCvEducation.splice(removeEduIndex, 1);
      }

      return {
        ...state,
        storedCvInfo: {
          ...state.storedCvInfo,
          education: removedCvEducation,
        },
        isUpdatingCvData: false,
      };
    }

    case actionTypes.REMOVE_EDUCATION_FAILED: {
      return { ...state, isUpdatingCvData: false };
    }

    case actionTypes.SUBMIT_ONBOARDING: {
      return { ...state, ...{ isSubmittingOnboarding: true, isSubmitOnboardingSuccess: false } };
    }

    case actionTypes.SUBMIT_ONBOARDING_SUCCESS: {
      const updatedDate = get(action.data, 'candidate.date_of_birth');

      return {
        ...state,
        ...{
          storedCvInfo: {
            ...state.storedCvInfo,
            basicInfo: {
              ...state.storedCvInfo.basicInfo,
              avatar: action.data.avatar,
              date_of_birth: updatedDate ? new Date(updatedDate) : null,
            },
          },
          isSubmittingOnboarding: false,
          isSubmitOnboardingSuccess: true,
        },
      };
    }

    case actionTypes.SUBMIT_ONBOARDING_FAILED: {
      return { ...state, ...{ isSubmittingOnboarding: false, isSubmitOnboardingSuccess: false } };
    }

    case actionTypes.GET_TERM_SUCCESS:
      return { ...state, ...{ term: action.data } };

    case actionTypes.GET_POLICY_SUCCESS:
      return { ...state, ...{ policy: action.data } };

    case actionTypes.GET_SAMPLE_CV_LIST: {
      return { ...state, isGetSampleCVLoading: true };
    }

    case actionTypes.GET_SAMPLE_CV_LIST_SUCCESS: {
      return {
        ...state,
        isGetSampleCVLoading: false,
        sampleCVList: action.data,
      };
    }

    case actionTypes.GET_SAMPLE_CV_LIST_FAILED: {
      return {
        ...state,
        isGetSampleCVLoading: false,
        error: action.error,
      };
    }

    case actionTypes.GET_CV_CATEGORY_LIST_SUCCESS: {
      return { ...state, cvCategoryList: action.data };
    }

    case actionTypes.GET_CV_CATEGORY_LIST_FAILED: {
      return { ...state, error: action.error };
    }

    case actionTypes.GET_CV_DETAIL_PAGE: {
      return { ...state, isGetCvDetailLoading: true };
    }

    case actionTypes.GET_CV_DETAIL_PAGE_SUCCESS: {
      return {
        ...state,
        cvDetailPage: { ...action.data },
        isGetCvDetailLoading: false,
      };
    }

    case actionTypes.GET_CV_DETAIL_PAGE_FAILED: {
      return {
        ...state,
        error: action.error,
        isGetCvDetailLoading: false,
      };
    }
    case actionTypes.GET_OCCUPATION_LIST: {
      return { ...state, isOccupationListLoading: true };
    }

    case actionTypes.GET_OCCUPATION_LIST_SUCCESS: {
      return {
        ...state,
        occupationList: action.data,
        isOccupationListLoading: false,
      };
    }

    case actionTypes.GET_OCCUPATION_LIST_FAILED: {
      return {
        ...state,
        error: action.error,
        isOccupationListLoading: false,
      };
    }

    case actionTypes.GET_OCCUPATION_DETAILS: {
      return { ...state, isOccupationDetailsLoading: true };
    }

    case actionTypes.GET_OCCUPATION_DETAILS_SUCCESS: {
      return {
        ...state,
        occupationDetails: action.data,
        isOccupationDetailsLoading: false,
      };
    }

    case actionTypes.GET_OCCUPATION_DETAILS_FAILED: {
      return {
        ...state,
        error: action.error,
        isOccupationDetailsLoading: false,
      };
    }

    case actionTypes.GET_SHARED_CV_DETAIL: {
      return { ...state, isSharedCVDetailsLoading: true };
    }

    case actionTypes.GET_SHARED_CV_DETAIL_SUCCESS: {
      const tempData = (action.data as unknown) as MyCVTemplateData;
      tempData.candidate.skill = tempData.candidate.skills;
      const resCvInfo = convertProfileDataToCvInfo((tempData as unknown) as Profile);

      return {
        ...state,
        sharedCVDetails: { ...resCvInfo, ...tempData },
        isSharedCVDetailsLoading: false,
      };
    }

    case actionTypes.GET_SHARED_CV_DETAIL_FAILED: {
      return {
        ...state,
        error: action.error,
        isSharedCVDetailsLoading: false,
      };
    }
    case actionTypes.GET_JOB_SCOPE_LIST: {
      return { ...state, isJobScopeListLoading: true };
    }

    case actionTypes.GET_DEGREE_LIST: {
      return { ...state, isDegreeListLoading: true };
    }

    case actionTypes.GET_SCHOOL_LIST: {
      return { ...state, isSchoolListLoading: true };
    }

    case actionTypes.GET_JOB_TYPE_LIST: {
      return { ...state, isJobTypeListLoading: true };
    }

    case actionTypes.GET_COMPANY_LIST: {
      return { ...state, isCompanyListLoading: true };
    }

    case actionTypes.ADD_REFERENCE: {
      return { ...state, isAddReferenceLoading: true };
    }

    case actionTypes.ADD_REFERENCE_SUCCESS: {
      return {
        ...state,
        isAddReferenceLoading: false,
        storedCvInfo: { ...state.storedCvInfo, reference: [...state.storedCvInfo.reference, action.data] },
      };
    }

    case actionTypes.ADD_REFERENCE_FAILED: {
      return { ...state, isAddReferenceLoading: false, error: action.error };
    }

    case actionTypes.GET_REFERENCE_SUCCESS:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, reference: action.data } };

    case actionTypes.GET_REFERENCE_FAILED:
      return { ...state, ...{ error: action.error } };

    case actionTypes.UPDATE_REFERENCE_SUCCESS: {
      const editedReference = { ...action.data };
      const editedCvReference = [...state.storedCvInfo.reference];
      const editingReferIndex = editedCvReference.findIndex((item) => item.id === editedReference.id);

      if (editingReferIndex >= 0) {
        editedCvReference[editingReferIndex] = editedReference;
      } else {
        editedCvReference.push(editedReference);
      }

      return { ...state, storedCvInfo: { ...state.storedCvInfo, reference: editedCvReference } };
    }

    case actionTypes.UPDATE_REFERENCE_ORDERING:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, reference: action.data } };

    case actionTypes.DELETE_REFERENCE: {
      return { ...state, isUpdatingCvData: true };
    }

    case actionTypes.DELETE_REFERENCE_SUCCESS: {
      const removedCvReference = [...state.storedCvInfo.reference];
      const removeReferIndex = removedCvReference.findIndex((item) => item.id === action.data.id);

      if (removeReferIndex >= 0) {
        removedCvReference.splice(removeReferIndex, 1);
      }

      return {
        ...state,
        storedCvInfo: { ...state.storedCvInfo, reference: removedCvReference },
        isUpdatingCvData: false,
      };
    }

    case actionTypes.DELETE_REFERENCE_FAILED: {
      return { ...state, isUpdatingCvData: false };
    }

    case actionTypes.ADD_CERTIFICATE: {
      return { ...state, isAddCertificateLoading: true };
    }

    case actionTypes.ADD_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        isAddCertificateLoading: false,
        storedCvInfo: { ...state.storedCvInfo, certifications: [...state.storedCvInfo.certifications, action.data] },
      };
    }

    case actionTypes.ADD_CERTIFICATE_FAILED: {
      return { ...state, isAddCertificateLoading: false, error: action.error };
    }

    case actionTypes.GET_CERTIFICATE_SUCCESS:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, certifications: action.data } };

    case actionTypes.GET_CERTIFICATE_FAILED:
      return { ...state, ...{ error: action.error } };

    case actionTypes.UPDATE_CERTIFICATE_SUCCESS: {
      const editedCertificate = { ...action.data };
      const editedCvCertificate = [...state.storedCvInfo.certifications];
      const editingReferIndex = editedCvCertificate.findIndex((item) => item.id === editedCertificate.id);

      if (editingReferIndex >= 0) {
        editedCvCertificate[editingReferIndex] = editedCertificate;
      } else {
        editedCvCertificate.push(editedCertificate);
      }

      return { ...state, storedCvInfo: { ...state.storedCvInfo, certifications: editedCvCertificate } };
    }

    case actionTypes.UPDATE_CERTIFICATE_ORDERING:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, certifications: action.data } };

    case actionTypes.DELETE_CERTIFICATE: {
      return { ...state, isUpdatingCvData: true };
    }

    case actionTypes.DELETE_CERTIFICATE_SUCCESS: {
      const removedCvCertificate = [...state.storedCvInfo.certifications];
      const removeReferIndex = removedCvCertificate.findIndex((item) => item.id === action.data.id);

      if (removeReferIndex >= 0) {
        removedCvCertificate.splice(removeReferIndex, 1);
      }

      return {
        ...state,
        storedCvInfo: { ...state.storedCvInfo, certifications: removedCvCertificate },
        isUpdatingCvData: false,
      };
    }

    case actionTypes.DELETE_CERTIFICATE_FAILED: {
      return { ...state, isUpdatingCvData: false };
    }

    case actionTypes.GET_LANGUAGE_SUCCESS:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, languages: action.data } };

    case actionTypes.GET_LANGUAGE_FAILED:
      return { ...state, ...{ error: action.error } };

    case actionTypes.ADD_LANGUAGE: {
      return { ...state, isAddLanguageLoading: true };
    }

    case actionTypes.ADD_LANGUAGE_SUCCESS: {
      return {
        ...state,
        isAddLanguageLoading: false,
        storedCvInfo: { ...state.storedCvInfo, languages: [...state.storedCvInfo.languages, action.data] },
      };
    }

    case actionTypes.ADD_LANGUAGE_FAILED: {
      return { ...state, isAddLanguageLoading: false, error: action.error };
    }

    case actionTypes.UPDATE_LANGUAGE_SUCCESS: {
      const editedLanguages = { ...action.data };
      const languages = [...state.storedCvInfo.languages];
      const editingReferIndex = languages.findIndex((item) => item.id === editedLanguages.id);

      if (editingReferIndex >= 0) {
        languages[editingReferIndex] = editedLanguages;
      } else {
        languages.push(editedLanguages);
      }

      return { ...state, storedCvInfo: { ...state.storedCvInfo, languages } };
    }

    case actionTypes.UPDATE_LANGUAGE_ORDERING:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, languages: action.data } };

    case actionTypes.DELETE_LANGUAGE: {
      return { ...state, isUpdatingCvData: true };
    }

    case actionTypes.DELETE_LANGUAGE_SUCCESS: {
      const languages = [...state.storedCvInfo.languages];
      const removeReferIndex = languages.findIndex((item) => item.id === action.data.id);

      if (removeReferIndex >= 0) {
        languages.splice(removeReferIndex, 1);
      }

      return {
        ...state,
        storedCvInfo: { ...state.storedCvInfo, languages },
        isUpdatingCvData: false,
      };
    }

    case actionTypes.DELETE_LANGUAGE_FAILED: {
      return { ...state, isUpdatingCvData: false };
    }

    case actionTypes.ADD_VOLUNTEER: {
      return { ...state, isAddVolunteerLoading: true };
    }

    case actionTypes.ADD_VOLUNTEER_SUCCESS: {
      return {
        ...state,
        isAddVolunteerLoading: false,
        storedCvInfo: { ...state.storedCvInfo, volunteers: [...state.storedCvInfo.volunteers, action.data] },
      };
    }

    case actionTypes.ADD_VOLUNTEER_FAILED: {
      return { ...state, isAddVolunteerLoading: false, error: action.error };
    }

    case actionTypes.GET_VOLUNTEER_SUCCESS:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, volunteers: action.data } };

    case actionTypes.GET_VOLUNTEER_FAILED:
      return { ...state, ...{ error: action.error } };

    case actionTypes.UPDATE_VOLUNTEER_SUCCESS: {
      const volunteers = { ...action.data };
      const editedCvVolunteer = [...state.storedCvInfo.volunteers];
      const editingIndex = editedCvVolunteer.findIndex((item) => item.id === volunteers.id);

      if (editingIndex >= 0) {
        editedCvVolunteer[editingIndex] = volunteers;
      } else {
        editedCvVolunteer.push(volunteers);
      }

      return { ...state, storedCvInfo: { ...state.storedCvInfo, volunteers: editedCvVolunteer } };
    }

    case actionTypes.UPDATE_VOLUNTEER_ORDERING:
      return { ...state, storedCvInfo: { ...state.storedCvInfo, volunteers: action.data } };

    case actionTypes.DELETE_VOLUNTEER: {
      return { ...state, isUpdatingCvData: true };
    }

    case actionTypes.DELETE_VOLUNTEER_SUCCESS: {
      const volunteers = [...state.storedCvInfo.volunteers];
      const removeIndex = volunteers.findIndex((item) => item.id === action.data.id);

      if (removeIndex >= 0) {
        volunteers.splice(removeIndex, 1);
      }

      return {
        ...state,
        storedCvInfo: { ...state.storedCvInfo, volunteers },
        isUpdatingCvData: false,
      };
    }

    case actionTypes.DELETE_VOLUNTEER_FAILED: {
      return { ...state, isUpdatingCvData: false };
    }

    case actionTypes.ADD_INTEREST_LIST: {
      return { ...state, storedCvInfo: { ...state.storedCvInfo, interest: [...action.data] } };
    }

    case actionTypes.DELETE_INTEREST_LIST: {
      const removedCvInterest = [...state.storedCvInfo.interest];

      if (action.idx >= 0) {
        removedCvInterest.splice(action.idx, 1);
      }

      return {
        ...state,
        storedCvInfo: { ...state.storedCvInfo, interest: removedCvInterest },
        isUpdatingCvData: false,
      };
    }

    case actionTypes.GET_REFERRAL_DETAIL_SUCCESS: {
      return { ...state, referralDetail: action.data };
    }

    case actionTypes.GET_REFERRAL_DETAIL_FAILED: {
      return { ...state, error: action.error };
    }

    default:
      return state;
  }
};

export default reducer;
