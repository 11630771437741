import { css } from '@emotion/react';

const utilities = css`
  .u-inline {
    display: inline !important;
  }

  .u-inline-block {
    display: inline-block !important;
  }

  .u-block {
    display: block !important;
  }

  .u-flex {
    display: flex !important;
  }

  .u-justify-between {
    justify-content: space-between !important;
  }

  .u-padding-0 {
    padding: 0 !important;
  }

  .u-items-center {
    align-items: center;
  }

  .u-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .u-word-break {
    white-space: pre-wrap;
    word-break: break-all;
    word-break: break-word;
  }

  .u-word-no-wrap {
    white-space: nowrap;
  }

  .u-text-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .u-text-line {
    text-transform: capitalize !important;
  }

  .u-filter-blur {
    backdrop-filter: blur(5px);
  }

  .u-4-lines-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .u-text-1line {
    @extend .u-text-line;
    -webkit-line-clamp: 1;
  }

  .u-text-center {
    text-align: center !important;
  }

  .u-pre-line {
    white-space: pre-line !important;
  }

  .u-mt-20 {
    margin-top: 20px !important;
  }

  .u-mt-30 {
    margin-top: 30px !important;
  }

  .u-mt-60 {
    margin-top: 60px !important;
  }

  .u-color-black {
    color: #000 !important;
  }

  .u-color-black-main {
    color: #212121 !important;
  }

  // Primary Color
  .u-color-blue-primary {
    color: #00447c !important;
  }

  .u-color-blue-light {
    color: #478df2 !important;
  }

  // Secondary Color
  .u-color-orange100 {
    color: #ff792e !important;
  }

  .u-color-orange80 {
    color: #ff9900 !important;
  }

  .u-color-error {
    color: #de1306 !important;
  }

  .u-bg-color-blue10 {
    background-color: #f6fbff;
  }

  .u-pointer {
    cursor: pointer !important;
  }

  .u-border-bottom {
    border-bottom: 1px solid #f2f2f2;
  }

  .u-link {
    color: #478df2;
    cursor: pointer;
  }

  .u-scale-y--1 {
    transform: scaleY(-1);
  }

  .u-border-rounded {
    border-radius: 1000px !important;
  }

  .u-hide-x-overflow {
    overflow-x: hidden !important;
  }

  .u-text-transform-initial {
    text-transform: initial !important;
  }

  .u-text-uppercase {
    text-transform: uppercase !important;
  }

  .u-text-underline {
    text-decoration: underline !important;
  }

  .u-max-w-full {
    max-width: 100% !important;
  }

  .u-word-break-all {
    word-break: break-all !important;
  }

  .u-relative {
    position: relative !important;
  }

  .u-events-disabled {
    pointer-events: none;
  }

  .u-center-self {
    transform: translate(-50%, 50%);
  }

  .u-center-content {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .u-scale-3 {
    transform: translate(-50%, -50%) scale(3);
  }

  .u-rotate-90 {
    transform: rotate(45deg);
  }

  .u-absolute-0 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export default utilities;
