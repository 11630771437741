import { css } from '@emotion/react';

const commons = css`
  h1 {
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 1.5px;
    font-weight: 800;

    @media (min-width: 960px) {
      font-size: 36px;
      line-height: 49px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.5px;

    @media (min-width: 960px) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  }

  p {
    line-height: 1.2;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  ul {
    list-style: none;
  }

  strong {
    font-weight: 600;
  }
`;

export default commons;
