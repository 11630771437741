import { css } from '@emotion/react';

export default css`
  #app-bar {
    color: #212121;
    background-color: transparent;
    box-shadow: none;
    padding-top: 16px;

    #header-nav {
      flex-grow: 1;
      text-align: right;
      padding-right: 40px;
    }

    #header-nav .MuiListItem-gutters {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .header-menu {
    z-index: 1500 !important;

    .MuiMenu-paper {
      box-shadow: 0px 6px 25px #c2c2c240;
    }

    .MuiMenu-list {
      min-width: 200px;
      box-shadow: 0 6.34211px 25.3684px hsl(0deg 0% 76% / 25%);

      .MuiListItem-button {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.25px;

        &:hover {
          background: #f7fbff;
          font-weight: 600;
          color: #00447c;
        }
      }
    }
  }

  #mobile-drawer {
    .MuiListItem-gutters {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .header {
    background: #ffffff;
    box-shadow: 0px 20px 10px -10px rgba(194, 194, 194, 0.1);

    .header--toolbar {
      margin-top: 36px;
      margin-bottom: 36px;
    }
  }
`;
