import { css } from '@emotion/react';

const typography = css`
  .o-heading1 {
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 1.5px;
    font-weight: 800;

    @media (min-width: 960px) {
      font-size: 36px;
      line-height: 49px;
    }
  }

  .o-body-regular1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.25px;

    @media (min-width: 960px) {
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }

  .o-body-regular2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .o-body-regular3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.25px;
  }

  .o-body-semibold1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.5px;
  }

  .o-body-semibold2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .o-body-semibold3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.25px;
  }

  .o-subtitle1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .o-subtitle2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .o-button-large {
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
  }

  .o-button-medium {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.25px;
    text-transform: initial;
  }

  .o-button-small {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
  }

  .o-button-mini {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  // Caption
  .o-caption1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .o-caption2 {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1px;
  }

  // shadown
  .o-shadown1 {
    box-shadow: 0px 4px 100px rgba(194, 194, 194, 0.1) !important;
  }

  .o-shadown2 {
    box-shadow: 0px 10px 10px rgba(194, 194, 194, 0.1) !important;
  }

  .o-shadown3 {
    box-shadow: 0px 4px 40px rgba(194, 194, 194, 0.2) !important;
  }

  .o-shadown4 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
      0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .o-button-bgc-blue {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    min-height: 48px;
    font-weight: 600;
    border-radius: 100px;
    background-color: #478df2;
    border: 1px solid #478df2;

    &:hover {
      background-color: #82b0f3;
      border-color: #82b0f3;
    }

    &.disabled {
      pointer-events: none;
      background-color: #eeeeee;
      border-color: #eeeeee;
    }
  }

  .o-button-bgc-white {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 48px;
    font-weight: 600;
    border-radius: 100px;
    background-color: white;
    color: #478df2;
    border: 1px solid #478df2;

    &:hover {
      background-color: whitesmoke;
    }
  }
`;

export default typography;
