import { css } from '@emotion/react';

const reset = css`
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  dl,
  dd,
  ol,
  ul,
  form,
  fieldset,
  legend,
  figure,
  table,
  th,
  td,
  caption,
  hr {
    margin: 0;
    padding: 0;
  }

  a,
  span {
    display: inline-block;
  }

  input,
  textarea,
  button {
    outline: none;
  }

  button {
    padding: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default reset;
