import createSagaMiddleware from 'redux-saga';
import { Middleware, Store, StoreEnhancer, applyMiddleware, createStore } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { createWrapper } from 'next-redux-wrapper';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import rootReducer from 'redux-saga/reducer';
import rootSaga from 'redux-saga/saga';
import { AppState } from 'redux-saga/interfaces';

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
  // if (process.env.NODE_ENV !== 'production') {
  //   return composeWithDevTools(applyMiddleware(...middleware));
  // }
  return applyMiddleware(...middleware);
};

export interface StoreProperties {
  __persistor?: Persistor;
}

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  let store: Store & StoreProperties = null;
  const isServer = typeof window === 'undefined';

  if (isServer) {
    // If it's on server side, create a store
    store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));
    store.sagaTask = sagaMiddleware.run(rootSaga);
    return store;
  }

  const persistConfig = {
    key: 'root',
    keyPrefix: '',
    whitelist: [
      'userInfo',
      'storedCvInfo',
      'cvSelected',
      'checkoutTransactionId',
      'username',
      'cvSettings',
      'profileInfo',
    ],
    storage: localStorage,
  };

  const persistedReducer = persistReducer<AppState>(persistConfig, rootReducer); // Create a new reducer with our existing reducer

  store = createStore(persistedReducer, bindMiddleware([sagaMiddleware])); // Creating the store again
  store.sagaTask = sagaMiddleware.run(rootSaga);
  store.__persistor = persistStore(store); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature

  return store;
};

export const wrapper = createWrapper(makeStore);
